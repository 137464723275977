import {globals} from "Figured/Assets/Modules";
import {number_format, currency_format} from "Figured/Assets/Modules/math.js";
import {MOMENT_DATE_FORMATS, ELEMENT_UI_DATE_FORMATS} from "Figured/Assets/Modules/dates";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined.js";
import BudgetVisibility  from "BudgetVisibility/Assets/Modules/budget-visibility";
import { lang } from "Assets/Modules";

/**
 * This mixin will be used by all components!
 *
 * You should only add things here if they are truly essential across the entire application.
 * The ideal situation here is to not have this mixin at all.
 */
export default {
    data() {
        return {
            /**
             *  TODO we should not be injecting this into the templates. Use computed properties instead.
             */
            lang,

            /**
             *  TODO we should not be injecting this into the templates. Use computed properties instead.
             */
            Utils: window.Utils,
        }
    },

    computed: {
        momentDateFormats() {
            return MOMENT_DATE_FORMATS;
        },

        elementUiDateFormats() {
            return ELEMENT_UI_DATE_FORMATS;
        },

        areaSymbol() {
            return lang.trans("terminology.area_symbol");
        },

        globalDateSelector() {
            return window.Figured.GlobalDateSelector;
        },
    },

    methods: {
        number_format,
        currency_format,
        trans: function() {
            return lang.trans.bind(lang,...arguments)();
        }
    }
};
